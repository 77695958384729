<template>
    <div class="">
        <div class="">
           <!-- <button class="btn btn-primary" @click="showText= !showText">Click Me</button> -->
            <!-- Main content -->
            
            <section class="content">
                <div class="container-fluid">
                    <!-- Main row -->
                    <div class="row">
                        <section class="col-lg-12 connectedSortable table-area mt-3 pr-0 pl-0 mb-3">
                           <div class="card ">                                
                               <div class="card-body table-responsive table-head">
                                    <div class="row bor-bot">
                                        <div class="col-md-10 pl-0">
                                            <h1 class="m-0 text-dark pt-2 pb-4 ">Volunteering</h1>
                                        </div>   
                                        <div class="col-md-2">
                                            <div class="text-right pb-3">
                                                <b-button v-b-modal.modal-center @click.prevent="addvolunteering" class="btn-blue"> + Add Volunteering</b-button>                                        
                                            </div>
                                        </div>                                 
                                    </div>
                                    <div class="table-responsive mt-3">
                                        <custom-alert v-if="displayAlert==true" :message="alertMessage" v-on:showedAlert="resetAlert" ></custom-alert>
                                        <table class="table table-sm table-bordered table-hover" id="list_table">
                                            <thead class="thead-dark">
                                            <tr>
                                                <th>Volunteering</th>
                                                <th>Status</th>
                                                <th>Role</th>
                                                <th>Actions</th>                                                
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <!-- Data will be placed here -->
                                            <tr>
                                                <td colspan="5">&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td colspan="5">&nbsp;</td>                                                
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                               </div>
                           </div>
                        </section>
                        <b-modal id="modal-center" size="lg" centered title="USAS Volunteer" ok-title="Save" @ok="saveVolunteering"  @hidden="resetModal">
                            <div class="popupbody">
                                <div class="row" id="volunteer_status">
                                    <div class="col-md-2"> 
                                        <label for="role" class="form-label">Status</label>  
                                    </div>
                                    <div class="col-md-10">     
                                        <div class="form-group">                                   
                                            <b-button variant="success"><i class="fas fa-check"></i> {{form.status}}</b-button>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-2"> 
                                        <label for="role" class="form-label">Role<sup class="text-danger">*</sup></label>  
                                    </div>
                                    <div class="col-md-10">     
                                        <div class="form-group">                                   
                                            <select class="form-control" id="role" v-model="form.role" @change="loadsubroles($event)" >
                                                <option value="">Select a Role</option>
                                                <option v-for="(role, id) in roles" :value="role.id" :key="id">{{ role.text }}</option>
                                            </select>
                                            <span class="text-sm text-danger">{{ errors.role }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" id="sub_roles">
                                    <div class="col-md-2"> 
                                        <label for="role" class="form-label"><sup class="text-danger">*</sup></label>  
                                    </div>
                                    <div class="col-md-10">  
                                        <div class="form-group">   
                                            <Select2 v-model="form.sub_roles" :options="sub_roles"  :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}" @change="loadother($event)"/> 
                                            <span class="text-sm text-danger">{{ errors.sub_roles }}</span> <br/>
                                            <small>Please select all of the roles that apply. If none of the roles fit, select 'Other' and complete the text box.</small> 
                                        </div>
                                    </div>   
                                </div> 
                                <div class="row" id="other_role">
                                    <div class="col-md-2"> 
                                        <label for="role" class="form-label">Other<sup class="text-danger">*</sup></label>  
                                    </div>
                                    <div class="col-md-10">  
                                        <div class="form-group">   
                                            <input type="text" class="form-control" v-model="form.other_role">
                                            <span class="text-sm text-danger">{{ errors.other_role }}</span>
                                        </div>
                                    </div>   
                                </div>
                            </div>
                        </b-modal>
                    </div>
                    <!-- /.row (main row) -->
                </div><!-- /.container-fluid -->
            </section>
        </div>
    </div>
</template>

<script>

import $ from 'jquery';
// import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-responsive-dt/js/responsive.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";
// import 'datatables.net-responsive-dt';
import moment from 'moment';
import axios from 'axios';
import CustomAlert from "../../../CustomAlert";

const validateRole = role => {
    if (role == null || role==0 || role == '') {
        return { valid: false, error: "This field is required" };
    }
    return { valid: true, error: null };
};

const validateOtherRole = (sub_roles,other_role) => {
    if ( (other_role=='' || other_role == undefined) && sub_roles.includes('-1') ) {
        return { valid: false, error: "This field is required" };
    }
    return { valid: true, error: null };
};

export default {
    name: 'volunteering',
    components: { CustomAlert },
    data() {
        return{
            table:'',
            form:{
                role:''
            },
            roles:'',         
            subroles:'',    
            alertMessage: "",
            displayAlert: false,      
        }
    },
    methods: {      
        loadsubroles(event){
            $("#sub_roles").css('display','flex');             
            if(event.target.options.selectedIndex!=0)
                this.sub_roles = this.roles[event.target.options.selectedIndex].sub_roles;   
            else     
                this.sub_roles = '';                 
        },
        loadother(event){             
            if(event.includes('-1'))
                $("#other_role").css('display','flex'); 
            else
            {
                $("#other_role").css('display','none'); 
                this.form.other_role='';  
            }                                      
        },
        validate() {
            this.errors = {}
            this.valid = false;
            const validRole = validateRole(this.form.role);
            this.errors.role = validRole.error;

            const validsubRole = validateRole(this.form.sub_roles);
            this.errors.sub_roles = validsubRole.error;
  
            const validotherRole = validateOtherRole(this.form.sub_roles,this.form.other_role);
            this.errors.other_role = validotherRole.error;

            if (validRole.valid && validsubRole.valid && validotherRole.valid)
            {
                this.valid = true;
            }
            return this.valid;
        },
        resetModal() {
            this.form = {
                role: '',
                sub_roles: '',  
                other_role: '',              
            }
        },
        saveVolunteering(bvModalEvt)
        {
            
            bvModalEvt.preventDefault();
            if (!this.validate()) {
                return;
            }
            axios.post(this.basePath+'api/member/volunteering/store', this.form, { headers: this.memberHeaders })
                .then((res) => {
                    this.displayAlert = true, 
                    this.alertMessage = res.data.message; 
                    this.table.draw();
                })
                .catch((error) => {
                    console.log('Error:'+ error);               
                    return false;
                
                }).finally(() => {
                    
                }
            );              
            this.errors = {};
            this.$nextTick(() => {
                this.$bvModal.hide('modal-center')
            });            
        },
        editRow(i)
        {
            this.$bvModal.show('modal-center');
            axios.get(this.basePath+'api/member/volunteering/'+i, { headers: this.memberHeaders })
                .then((res) => { 
                    this.form={};
                    this.form.status = (res.data.data.status == 1) ? 'Current' : 'Invalid'; 
                    $("#sub_roles, #volunteer_status").css('display','flex'); 
                    this.form.role = res.data.data.role;  
                    $("#role").attr('disabled','true');  
                    this.sub_roles = this.roles[res.data.data.role].sub_roles;  
                    this.form.sub_roles = res.data.data.sub_roles.split(","); 
                    if(res.data.data.sub_roles.split(",").includes('-1'))
                    $("#other_role").css('display','flex');  
                    this.form.other_role = res.data.data.other_role;
                    this.form.current_id = res.data.data.id;
                })
                .catch((error) => {
                    console.log('Error:'+ error);               
                    return false;            
                }).finally(() => {
                    
                }
            );             
        },
        deleteRow(id){
            if(confirm("Are you sure you want to delete?")){
                fetch(process.env.VUE_APP_URL + "api/member/volunteering/"+id,{
                    method:'delete',
                    headers: this.memberHeaders,
                })
                .then(res => res.json())
                .then(data => {
                    if(data.status == "success")
                    {
                        this.alertMessage = "Volunteering deleted successfully";
                        this.displayAlert = true;
                        this.table.draw(); 
                    }                 
                })
                .catch(err => console.log(err));
            }
        }
    },
    
    mounted(){     
        let self=this;
        // const retry = setInterval(() => {
        //     this.roles = this.getSiteProps('volunteering.role');                       
        //     if(this.roles != "") clearInterval(retry);   
        //     console.log( this.roles );          
        // }, 200);
         
        axios.get(this.basePath+'api/member/volunteering/roles', { headers: this.memberHeaders })
            .then((res) => { 
                this.roles = res.data.data;
            })
            .catch((error) => {
                console.log('Error:'+ error);               
                return false;            
            }).finally(() => {
                
            }
        );  
         
        this.table = $("#list_table").DataTable({
            dom: 'lfrtip',
            bFilter: true,
            processing: true,   
            serverSide: true,       
            pageLength: 10,
            responsive: true,
            ajax: {
                url: process.env.VUE_APP_URL + "api/member/volunteering/"+this.loggedMemberInfo.person_id,
                type: 'post',  
                headers:{
                    "Authorization":"Bearer "+localStorage.getItem('membertoken')
                },                 
            },
            columns: [
                { data: 'volunteer', name: 'volunteer', orderable: false, render: (data)=>{return data || "N/A";}},
                { data: "status", name: "status", orderable: false, render: (data)=>{ if(data=='Current') {return'<b-button pill variant="success" class="btn btn-success rounded-pill">'+data+'</b-button>'} else { return'<b-button pill variant="success" class="btn btn-danger rounded-pill">'+data+'</b-button>'} } }, 
                { data: 'role', name: 'role', orderable: false, render: (data)=>{return data || "N/A";}},  
                { data: "actions", name: "actions", orderable: false,
                    render: (data,type,row,meta) => {
                        let btn = '';                             
                        btn += `<a class="actions-edit" data-id="${row.id}" href="javascript:void(0);"><i class="fas fa-edit"></i></a>  <a class="actions-delete" data-id="${row.id}" href="javascript:void(0);"><i class="fas fa-trash"></i></a>` 
                        return btn
                    }
                },
            ],            
            order: [1, 'desc']
        });

        document.addEventListener("click", function(e){
            if(e.target && e.target.parentNode.className == "actions-edit"){
                var id = e.target.parentNode.getAttribute('data-id');
                self.editRow(id);
            }
            if(e.target && e.target.parentNode.className == "actions-delete"){
                var delete_id = e.target.parentNode.getAttribute('data-id');
                self.deleteRow(delete_id);
            }
        });
    }
}
</script>

<style scoped>
#volunteer_status,#other_role,#sub_roles
{
    display: none;
}

</style>